$(document).on("turbolinks:load", () => {
  let cat = $('#person_CG_Categoria');
  let condutor = $('#person_CG_Condutor');
  if (cat.val() !=  'NAO') {
    $('#parentesco').hide();
    $('#person_CG_GrauDeParentesco').prop('required',false);
  }
  if (!condutor.is(':checked')) {
    $('#email').hide();
    // $('#person_CG_Email').prop('required',false);
  }
  cat.change(e =>{
    if (cat.val() == 'NAO') {
      $('#parentesco').show(500, () =>{    
        $('#person_CG_GrauDeParentesco').prop('required',true);
      });
    }
    else {
      $('#parentesco').hide(100, () => {
        $('#person_CG_GrauDeParentesco').prop('required',false);
      });
    }
  })

  
  condutor.change(e =>{
    if (condutor.is(':checked')){
      $('#email').show(300, ()=> {
        // $('#person_CG_Email').prop('required',true);
      });
    }
    else {
      $('#email').hide(100, () =>{
        // $('#person_CG_Email').prop('required',false);
      })
      
    }
  })
  // cat.change(e =>{
  //   console.log(cat.val())
  // });
})